import React from 'react';
import { Box, Container, Typography, Divider, useTheme, Paper } from '@mui/material';

const CookiePolicy = () => {
  const theme = useTheme();

  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="md">
        <Paper 
          elevation={0}
          sx={{
            p: { xs: 3, md: 5 },
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Typography variant="h3" component="h1" gutterBottom sx={{ fontWeight: 700 }}>
            Cookie Policy
          </Typography>
          
          <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 4 }}>
            Last updated: {new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
          </Typography>
          
          <Divider sx={{ mb: 4 }} />
          
          <Typography paragraph>
            This Cookie Policy explains how Pillar1 ("Company", "we", "us", and "our") uses cookies and similar technologies to recognize you when you visit our website at pillar1.com ("Website"). It explains what these technologies are and why we use them, as well as your rights to control our use of them.
          </Typography>
          
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, fontWeight: 600 }}>
            What are cookies?
          </Typography>
          
          <Typography paragraph>
            Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to provide reporting information.
          </Typography>
          
          <Typography paragraph>
            Cookies set by the website owner (in this case, Pillar1) are called "first-party cookies". Cookies set by parties other than the website owner are called "third-party cookies". Third-party cookies enable third-party features or functionality to be provided on or through the website (e.g., advertising, interactive content, and analytics). The parties that set these third-party cookies can recognize your computer both when it visits the website in question and also when it visits certain other websites.
          </Typography>
          
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, fontWeight: 600 }}>
            Why do we use cookies?
          </Typography>
          
          <Typography paragraph>
            We use first- and third-party cookies for several reasons. Some cookies are required for technical reasons in order for our Website to operate, and we refer to these as "essential" or "strictly necessary" cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on our Online Properties. Third parties serve cookies through our Website for advertising, analytics, and other purposes. This is described in more detail below.
          </Typography>
          
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, fontWeight: 600 }}>
            The specific types of first- and third-party cookies served through our Website and the purposes they perform
          </Typography>
          
          <Typography variant="h6" component="h3" gutterBottom sx={{ mt: 3, fontWeight: 600 }}>
            Essential website cookies:
          </Typography>
          
          <Typography paragraph>
            These cookies are strictly necessary to provide you with services available through our Website and to use some of its features, such as access to secure areas.
          </Typography>
          
          <Box sx={{ mb: 4 }}>
            <Typography component="div" variant="body2" sx={{ bgcolor: 'grey.100', p: 2, borderRadius: 1 }}>
              <strong>Name:</strong> session<br />
              <strong>Purpose:</strong> Used to maintain an anonymous user session by the server.<br />
              <strong>Provider:</strong> pillar1.com<br />
              <strong>Service:</strong> Website functionality<br />
              <strong>Country:</strong> United States<br />
              <strong>Type:</strong> http_cookie<br />
              <strong>Expires in:</strong> Session
            </Typography>
          </Box>
          
          <Typography variant="h6" component="h3" gutterBottom sx={{ mt: 3, fontWeight: 600 }}>
            Performance and functionality cookies:
          </Typography>
          
          <Typography paragraph>
            These cookies are used to enhance the performance and functionality of our Website but are non-essential to their use. However, without these cookies, certain functionality may become unavailable.
          </Typography>
          
          <Box sx={{ mb: 4 }}>
            <Typography component="div" variant="body2" sx={{ bgcolor: 'grey.100', p: 2, borderRadius: 1 }}>
              <strong>Name:</strong> ui_preferences<br />
              <strong>Purpose:</strong> Used to remember user preferences in website functionality.<br />
              <strong>Provider:</strong> pillar1.com<br />
              <strong>Service:</strong> Functionality preferences<br />
              <strong>Country:</strong> United States<br />
              <strong>Type:</strong> http_cookie<br />
              <strong>Expires in:</strong> 1 year
            </Typography>
          </Box>
          
          <Typography variant="h6" component="h3" gutterBottom sx={{ mt: 3, fontWeight: 600 }}>
            Analytics and customization cookies:
          </Typography>
          
          <Typography paragraph>
            These cookies collect information that is used either in aggregate form to help us understand how our Website is being used or how effective our marketing campaigns are, or to help us customize our Website for you.
          </Typography>
          
          <Box sx={{ mb: 4 }}>
            <Typography component="div" variant="body2" sx={{ bgcolor: 'grey.100', p: 2, borderRadius: 1 }}>
              <strong>Name:</strong> _ga<br />
              <strong>Purpose:</strong> Used to distinguish users for Google Analytics.<br />
              <strong>Provider:</strong> .pillar1.com<br />
              <strong>Service:</strong> Google Analytics<br />
              <strong>Country:</strong> United States<br />
              <strong>Type:</strong> http_cookie<br />
              <strong>Expires in:</strong> 2 years
            </Typography>
          </Box>
          
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, fontWeight: 600 }}>
            How can I control cookies?
          </Typography>
          
          <Typography paragraph>
            You can set or amend your web browser controls to accept or refuse cookies. If you choose to reject cookies, you may still use our website though your access to some functionality and areas of our website may be restricted. As the means by which you can refuse cookies through your web browser controls vary from browser-to-browser, you should visit your browser's help menu for more information.
          </Typography>
          
          <Typography paragraph>
            In addition, most advertising networks offer you a way to opt out of targeted advertising. If you would like to find out more information, please visit http://www.aboutads.info/choices/ or http://www.youronlinechoices.com.
          </Typography>
          
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, fontWeight: 600 }}>
            How often will you update this Cookie Policy?
          </Typography>
          
          <Typography paragraph>
            We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use or for other operational, legal, or regulatory reasons. Please therefore revisit this Cookie Policy regularly to stay informed about our use of cookies and related technologies.
          </Typography>
          
          <Typography paragraph>
            The date at the top of this Cookie Policy indicates when it was last updated.
          </Typography>
          
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, fontWeight: 600 }}>
            Where can I get further information?
          </Typography>
          
          <Typography paragraph>
            If you have any questions about our use of cookies or other technologies, please email us at pillar1@gmail.com or using the following contact information:
          </Typography>
          
          <Typography paragraph>
            Pillar1<br />
            Email: pillar1@gmail.com<br />
            Address: Charlotte, NC 28105
          </Typography>
        </Paper>
      </Container>
    </Box>
  );
};

export default CookiePolicy;