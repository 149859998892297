import React from 'react';
import { Box, Container, Typography, Divider, useTheme, Paper } from '@mui/material';

const TermsOfService = () => {
  const theme = useTheme();

  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="md">
        <Paper 
          elevation={0}
          sx={{
            p: { xs: 3, md: 5 },
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Typography variant="h3" component="h1" gutterBottom sx={{ fontWeight: 700 }}>
            Terms of Service
          </Typography>
          
          <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 4 }}>
            Last updated: {new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
          </Typography>
          
          <Divider sx={{ mb: 4 }} />
          
          <Typography paragraph>
            Please read these Terms of Service ("Terms", "Terms of Service") carefully before using the website operated by Pillar1 ("us", "we", or "our").
          </Typography>
          
          <Typography paragraph>
            Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, and others who access or use the Service.
          </Typography>
          
          <Typography paragraph>
            By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service.
          </Typography>
          
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, fontWeight: 600 }}>
            1. Use of Services
          </Typography>
          
          <Typography paragraph>
            The Pillar1 website and its content, features, and functionality are owned by Pillar1 and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
          </Typography>
          
          <Typography paragraph>
            You agree not to use the Service:
          </Typography>
          
          <Box component="ul" sx={{ pl: 4 }}>
            <Typography component="li" paragraph>In any way that violates any applicable national or international law or regulation</Typography>
            <Typography component="li" paragraph>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter," "spam," or any other similar solicitation</Typography>
            <Typography component="li" paragraph>To impersonate or attempt to impersonate Pillar1, a Pillar1 employee, another user, or any other person or entity</Typography>
            <Typography component="li" paragraph>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Service, or which, as determined by us, may harm Pillar1 or users of the Service or expose them to liability</Typography>
          </Box>
          
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, fontWeight: 600 }}>
            2. Contact Form and Communications
          </Typography>
          
          <Typography paragraph>
            By using our contact form or sending us email communications, you expressly consent to receive informational, marketing, and other communications from us. We will handle your contact information in accordance with our Privacy Policy.
          </Typography>
          
          <Typography paragraph>
            If you do not wish to receive marketing communications from us, you can opt out by:
          </Typography>
          
          <Box component="ul" sx={{ pl: 4 }}>
            <Typography component="li" paragraph>Following the opt-out instructions contained in the marketing emails</Typography>
            <Typography component="li" paragraph>Contacting us at pillar1@gmail.com</Typography>
          </Box>
          
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, fontWeight: 600 }}>
            3. Intellectual Property
          </Typography>
          
          <Typography paragraph>
            The Service and its original content (excluding content provided by users), features, and functionality are and will remain the exclusive property of Pillar1 and its licensors. The Service is protected by copyright, trademark, and other laws of both the United States and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Pillar1.
          </Typography>
          
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, fontWeight: 600 }}>
            4. Links To Other Web Sites
          </Typography>
          
          <Typography paragraph>
            Our Service may contain links to third-party web sites or services that are not owned or controlled by Pillar1.
          </Typography>
          
          <Typography paragraph>
            Pillar1 has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party web sites or services. You further acknowledge and agree that Pillar1 shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods, or services available on or through any such web sites or services.
          </Typography>
          
          <Typography paragraph>
            We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.
          </Typography>
          
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, fontWeight: 600 }}>
            5. Termination
          </Typography>
          
          <Typography paragraph>
            We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.
          </Typography>
          
          <Typography paragraph>
            All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
          </Typography>
          
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, fontWeight: 600 }}>
            6. Disclaimer
          </Typography>
          
          <Typography paragraph>
            Your use of the Service is at your sole risk. The Service is provided on an "AS IS" and "AS AVAILABLE" basis. The Service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement, or course of performance.
          </Typography>
          
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, fontWeight: 600 }}>
            7. Governing Law
          </Typography>
          
          <Typography paragraph>
            These Terms shall be governed and construed in accordance with the laws of North Carolina, United States, without regard to its conflict of law provisions.
          </Typography>
          
          <Typography paragraph>
            Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.
          </Typography>
          
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, fontWeight: 600 }}>
            8. Changes
          </Typography>
          
          <Typography paragraph>
            We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will try to provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
          </Typography>
          
          <Typography paragraph>
            By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms. If you do not agree to the new terms, please stop using the Service.
          </Typography>
          
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, fontWeight: 600 }}>
            9. Contact Us
          </Typography>
          
          <Typography paragraph>
            If you have any questions about these Terms, please contact us:
          </Typography>
          
          <Typography paragraph>
            Pillar1<br />
            Email: pillar1@gmail.com<br />
            Address: Charlotte, NC 28105
          </Typography>
        </Paper>
      </Container>
    </Box>
  );
};

export default TermsOfService;