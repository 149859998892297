import React from 'react';
import { Box, Container, Typography, Divider, useTheme, Paper } from '@mui/material';

const PrivacyPolicy = () => {
  const theme = useTheme();

  return (
    <Box sx={{ py: 8 }}>
      <Container maxWidth="md">
        <Paper 
          elevation={0}
          sx={{
            p: { xs: 3, md: 5 },
            borderRadius: 2,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Typography variant="h3" component="h1" gutterBottom sx={{ fontWeight: 700 }}>
            Privacy Policy
          </Typography>
          
          <Typography variant="subtitle1" color="text.secondary" sx={{ mb: 4 }}>
            Last updated: {new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}
          </Typography>
          
          <Divider sx={{ mb: 4 }} />
          
          <Typography paragraph>
            At Pillar1, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website or use our services. Please read this privacy policy carefully. If you do not agree with the terms of this privacy policy, please do not access the site.
          </Typography>
          
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, fontWeight: 600 }}>
            1. COLLECTION OF YOUR INFORMATION
          </Typography>
          
          <Typography paragraph>
            We may collect information about you in a variety of ways. The information we may collect on the Site includes:
          </Typography>
          
          <Typography variant="h6" component="h3" gutterBottom sx={{ mt: 3, fontWeight: 600 }}>
            Personal Data
          </Typography>
          
          <Typography paragraph>
            Personally identifiable information, such as your name, email address, telephone number, and company that you voluntarily give to us when you register with the Site or when you choose to participate in various activities related to the Site, such as submitting an inquiry through our contact form. You are under no obligation to provide us with personal information of any kind, however your refusal to do so may prevent you from using certain features of the Site.
          </Typography>
          
          <Typography variant="h6" component="h3" gutterBottom sx={{ mt: 3, fontWeight: 600 }}>
            Derivative Data
          </Typography>
          
          <Typography paragraph>
            Information our servers automatically collect when you access the Site, such as your IP address, browser type, operating system, access times, and the pages you have viewed directly before and after accessing the Site.
          </Typography>
          
          <Typography variant="h6" component="h3" gutterBottom sx={{ mt: 3, fontWeight: 600 }}>
            Cookies and Web Beacons
          </Typography>
          
          <Typography paragraph>
            We may use cookies, web beacons, tracking pixels, and other tracking technologies on the Site to help customize the Site and improve your experience. By using the Site, you agree to be bound by our Cookie Policy, which is incorporated into this Privacy Policy. For more information about how we use cookies and similar technologies, please read our Cookie Policy.
          </Typography>
          
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, fontWeight: 600 }}>
            2. USE OF YOUR INFORMATION
          </Typography>
          
          <Typography paragraph>
            Having accurate information about you permits us to provide you with a smooth, efficient, and customized experience. Specifically, we may use information collected about you via the Site to:
          </Typography>
          
          <Box component="ul" sx={{ pl: 4 }}>
            <Typography component="li" paragraph>Create and manage your account</Typography>
            <Typography component="li" paragraph>Respond to your inquiries and contact requests</Typography>
            <Typography component="li" paragraph>Send you a newsletter or marketing communications</Typography>
            <Typography component="li" paragraph>Compile anonymous statistical data and analysis for use internally</Typography>
            <Typography component="li" paragraph>Increase the efficiency and operation of the Site</Typography>
            <Typography component="li" paragraph>Monitor and analyze usage and trends to improve your experience with the Site</Typography>
            <Typography component="li" paragraph>Fulfill and manage your requests for services</Typography>
          </Box>
          
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, fontWeight: 600 }}>
            3. DISCLOSURE OF YOUR INFORMATION
          </Typography>
          
          <Typography paragraph>
            We may share information we have collected about you in certain situations. Your information may be disclosed as follows:
          </Typography>
          
          <Typography variant="h6" component="h3" gutterBottom sx={{ mt: 3, fontWeight: 600 }}>
            By Law or to Protect Rights
          </Typography>
          
          <Typography paragraph>
            If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.
          </Typography>
          
          <Typography variant="h6" component="h3" gutterBottom sx={{ mt: 3, fontWeight: 600 }}>
            Third-Party Service Providers
          </Typography>
          
          <Typography paragraph>
            We may share your information with third parties that perform services for us or on our behalf, including data analysis, email delivery, hosting services, customer service, and marketing assistance.
          </Typography>
          
          <Typography variant="h6" component="h3" gutterBottom sx={{ mt: 3, fontWeight: 600 }}>
            Business Transfers
          </Typography>
          
          <Typography paragraph>
            If we or our subsidiaries are involved in a merger, acquisition, financing, reorganization, bankruptcy, receivership, sale of company assets, or transition of service to another provider, then your information may be sold or transferred as part of such a transaction as permitted by law and/or contract.
          </Typography>
          
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, fontWeight: 600 }}>
            4. SECURITY OF YOUR INFORMATION
          </Typography>
          
          <Typography paragraph>
            We use administrative, technical, and physical security measures to help protect your personal information. While we have taken reasonable steps to secure the personal information you provide to us, please be aware that despite our efforts, no security measures are perfect or impenetrable, and no method of data transmission can be guaranteed against any interception or other type of misuse.
          </Typography>
          
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, fontWeight: 600 }}>
            5. YOUR RIGHTS REGARDING YOUR DATA
          </Typography>
          
          <Typography paragraph>
            Under the General Data Protection Regulation (GDPR), if you are a resident of the European Economic Area (EEA), you have certain data protection rights. Pillar1 aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.
          </Typography>
          
          <Typography paragraph>
            If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.
          </Typography>
          
          <Typography paragraph>
            In certain circumstances, you have the following data protection rights:
          </Typography>
          
          <Box component="ul" sx={{ pl: 4 }}>
            <Typography component="li" paragraph>The right to access, update or to delete the information we have on you</Typography>
            <Typography component="li" paragraph>The right of rectification - the right to have your information corrected if it is inaccurate or incomplete</Typography>
            <Typography component="li" paragraph>The right to object to our processing of your Personal Data</Typography>
            <Typography component="li" paragraph>The right of restriction - the right to request that we restrict the processing of your personal information</Typography>
            <Typography component="li" paragraph>The right to data portability - the right to be provided with a copy of the information we have on you in a structured, machine-readable and commonly used format</Typography>
            <Typography component="li" paragraph>The right to withdraw consent - the right to withdraw your consent at any time where we relied on your consent to process your personal information</Typography>
          </Box>
          
          <Typography paragraph>
            Please note that we may ask you to verify your identity before responding to such requests. You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data.
          </Typography>
          
          <Typography variant="h5" component="h2" gutterBottom sx={{ mt: 4, fontWeight: 600 }}>
            6. CONTACT US
          </Typography>
          
          <Typography paragraph>
            If you have questions or comments about this Privacy Policy, please contact us at:
          </Typography>
          
          <Typography paragraph>
            Pillar1<br />
            Email: pillar1@gmail.com<br />
            Address: Charlotte, NC 28105
          </Typography>
        </Paper>
      </Container>
    </Box>
  );
};

export default PrivacyPolicy;