import { useState, useEffect } from "react";
import {
	Box,
	Typography,
	useTheme,
	Grid,
	Container,
	Divider,
	Paper,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import heroBackgroundImage from "images/aiPhotos/9.jpg";
import ContactForm from "../components/ContactForm";

const ContactPage = () => {
	const theme = useTheme();
	const [animate, setAnimate] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => setAnimate(true), 200);
		return () => clearTimeout(timer);
	}, []);

	return (
		<Box
			sx={{
				position: "relative",
				py: { xs: 6, md: 10 },
				"&::before": {
					content: '""',
					position: "absolute",
					top: 0,
					left: 0,
					width: "100%",
					height: "100%",
					backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.85)), url(${heroBackgroundImage})`,
					backgroundSize: "cover",
					backgroundPosition: "center",
					backgroundRepeat: "no-repeat",
					backgroundAttachment: "fixed",
					zIndex: -1,
				},
			}}
		>
			<Container maxWidth="lg">
				<Grid
					container
					spacing={4}
					alignItems="center"
				>
					<Grid
						item
						xs={12}
						md={5}
						sx={{
							opacity: animate ? 1 : 0,
							transform: animate ? "translateX(0)" : "translateX(-20px)",
							transition: "opacity 0.8s ease, transform 0.8s ease",
						}}
					>
						<Typography
							variant="h2"
							component="h1"
							sx={{
								fontWeight: 700,
								mb: 3,
								fontSize: { xs: "2.5rem", md: "3.25rem" },
							}}
						>
							Get in Touch
						</Typography>

						<Typography
							variant="h6"
							sx={{
								mb: 4,
								lineHeight: 1.6,
							}}
						>
							Let's discuss how we can help transform your business with AI
							solutions tailored to your needs.
						</Typography>

						<Box sx={{ mb: 5 }}>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									mb: 3,
									opacity: animate ? 1 : 0,
									transform: animate ? "translateY(0)" : "translateY(10px)",
									transition: "opacity 1s ease, transform 1s ease",
									transitionDelay: "0.2s",
								}}
							>
								<Paper
									elevation={0}
									sx={{
										p: 1.5,
										mr: 2,
										borderRadius: 2,
										backgroundColor: theme.palette.primary.main,
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<EmailIcon sx={{ color: "white" }} />
								</Paper>
								<Box>
									<Typography
										variant="subtitle2"
										sx={{ fontWeight: 600 }}
									>
										Email Us
									</Typography>
									<Typography
										variant="body2"
										sx={{ color: "rgba(0, 0, 0, 0.7)" }}
									>
										pillar1@gmail.com
									</Typography>
								</Box>
							</Box>

							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									mb: 3,
									opacity: animate ? 1 : 0,
									transform: animate ? "translateY(0)" : "translateY(10px)",
									transition: "opacity 1s ease, transform 1s ease",
									transitionDelay: "0.3s",
								}}
							>
								<Paper
									elevation={0}
									sx={{
										p: 1.5,
										mr: 2,
										borderRadius: 2,
										backgroundColor: theme.palette.primary.main,
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<PhoneIcon sx={{ color: "white" }} />
								</Paper>
								<Box>
									<Typography
										variant="subtitle2"
										sx={{ fontWeight: 600 }}
									>
										Call Us
									</Typography>
									<Typography
										variant="body2"
										sx={{ color: "rgba(0, 0, 0, 0.7)" }}
									>
										+1 (704) 607-1566
									</Typography>
								</Box>
							</Box>

							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									opacity: animate ? 1 : 0,
									transform: animate ? "translateY(0)" : "translateY(10px)",
									transition: "opacity 1s ease, transform 1s ease",
									transitionDelay: "0.4s",
								}}
							>
								<Paper
									elevation={0}
									sx={{
										p: 1.5,
										mr: 2,
										borderRadius: 2,
										backgroundColor: theme.palette.primary.main,
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<LocationOnIcon sx={{ color: "white" }} />
								</Paper>
								<Box>
									<Typography
										variant="subtitle2"
										sx={{ fontWeight: 600 }}
									>
										Our Location
									</Typography>
									<Typography
										variant="body2"
										sx={{ color: "rgba(0, 0, 0, 0.7)" }}
									>
										Charlotte, NC 28105
									</Typography>
								</Box>
							</Box>
						</Box>

						<Divider sx={{ borderColor: "rgba(255, 255, 255, 0.1)", my: 4 }} />

						<Typography
							variant="body2"
							sx={{
								color: "rgba(0, 0, 0, 0.6)",
								fontStyle: "italic",
							}}
						>
							We typically respond to inquiries within 24 hours.
						</Typography>
					</Grid>

					<Grid
						item
						xs={12}
						md={7}
						sx={{
							opacity: animate ? 1 : 0,
							transform: animate ? "translateX(0)" : "translateX(20px)",
							transition: "opacity 0.8s ease, transform 0.8s ease",
						}}
					>
						<ContactForm />
					</Grid>
				</Grid>
			</Container>
		</Box>
	);
};

export default ContactPage;
