import { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  TextField,
  Card,
  CardContent,
  Alert,
  Snackbar,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Link,
  useTheme,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import emailjs from "emailjs-com";

const ServiceID = "service_6z495vr";
const templateID = "template_pta4j6d";
const userID = "eu5oNFR-km4D26Cwp";

const ContactForm = () => {
  const theme = useTheme();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("Failed to send message. Please try again later.");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    company: "",
    websiteURL: "",
    message: "",
    gdprConsent: false,
  });

  const resetForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      company: "",
      websiteURL: "",
      message: "",
      gdprConsent: false,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(false);

    // Stop if GDPR consent is not given
    if (!formData.gdprConsent) {
      setErrorMessage("You must consent to our Privacy Policy to submit the form.");
      setError(true);
      setIsSubmitting(false);
      return;
    }

    emailjs
      .send(
        ServiceID,
        templateID,
        {
          firstName: formData.firstName + " " + formData.lastName,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          company: formData.company,
          websiteURL: formData.websiteURL,
          message: formData.message,
          gdprConsent: formData.gdprConsent ? "Yes" : "No",
        },
        userID
      )
      .then(
        (response) => {
          setSuccess(true);
          resetForm();
        },
        (err) => {
          console.error("FAILED...", err);
          setErrorMessage("Failed to send message. Please try again later.");
          setError(true);
        }
      )
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleCloseSuccess = () => {
    setSuccess(false);
  };

  const handleCloseError = () => {
    setError(false);
  };

  return (
    <Card
      elevation={0}
      sx={{
        borderRadius: 3,
        backgroundColor: "rgba(255, 255, 255, 0.95)",
        overflow: "visible",
        position: "relative",
        "&::before": {
          content: '""',
          position: "absolute",
          top: -8,
          left: -8,
          right: -8,
          bottom: -8,
          zIndex: -1,
          background: `linear-gradient(135deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
          borderRadius: 4,
          opacity: 0.7,
        },
      }}
    >
      <CardContent sx={{ p: { xs: 3, md: 4 } }}>
        <Typography
          variant="h5"
          component="h2"
          sx={{
            mb: 3,
            color: theme.palette.primary.main,
            fontWeight: 600,
          }}
        >
          Send us a message
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: 1 }}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              xs={12}
              sm={6}
            >
              <TextField
                name="firstName"
                label="First Name"
                fullWidth
                required
                variant="outlined"
                value={formData.firstName}
                onChange={handleChange}
                sx={{
                  "& .MuiInputBase-input": {
                    color: theme.palette.text.primary,
                  },
                  "& .MuiFormLabel-root": {
                    color: theme.palette.text.secondary,
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <TextField
                name="lastName"
                label="Last Name"
                fullWidth
                required
                variant="outlined"
                value={formData.lastName}
                onChange={handleChange}
                sx={{
                  "& .MuiInputBase-input": {
                    color: theme.palette.text.primary,
                  },
                  "& .MuiFormLabel-root": {
                    color: theme.palette.text.secondary,
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <TextField
                name="email"
                label="Email Address"
                type="email"
                fullWidth
                required
                variant="outlined"
                value={formData.email}
                onChange={handleChange}
                sx={{
                  "& .MuiInputBase-input": {
                    color: theme.palette.text.primary,
                  },
                  "& .MuiFormLabel-root": {
                    color: theme.palette.text.secondary,
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <TextField
                name="phoneNumber"
                label="Phone Number"
                fullWidth
                variant="outlined"
                value={formData.phoneNumber}
                onChange={handleChange}
                sx={{
                  "& .MuiInputBase-input": {
                    color: theme.palette.text.primary,
                  },
                  "& .MuiFormLabel-root": {
                    color: theme.palette.text.secondary,
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <TextField
                name="company"
                label="Company"
                fullWidth
                variant="outlined"
                value={formData.company}
                onChange={handleChange}
                sx={{
                  "& .MuiInputBase-input": {
                    color: theme.palette.text.primary,
                  },
                  "& .MuiFormLabel-root": {
                    color: theme.palette.text.secondary,
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
            >
              <TextField
                name="websiteURL"
                label="Website URL"
                fullWidth
                variant="outlined"
                value={formData.websiteURL}
                onChange={handleChange}
                sx={{
                  "& .MuiInputBase-input": {
                    color: theme.palette.text.primary,
                  },
                  "& .MuiFormLabel-root": {
                    color: theme.palette.text.secondary,
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <TextField
                name="message"
                label="Message"
                multiline
                rows={5}
                fullWidth
                required
                variant="outlined"
                value={formData.message}
                onChange={handleChange}
                placeholder="Tell us about your project..."
                sx={{
                  "& .MuiInputBase-input": {
                    color: theme.palette.text.primary,
                  },
                  "& .MuiFormLabel-root": {
                    color: theme.palette.text.secondary,
                  },
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="gdprConsent"
                    checked={formData.gdprConsent}
                    onChange={handleChange}
                    color="primary"
                    required
                  />
                }
                label={
                  <Typography variant="body2" color="text.secondary">
                    I consent to Pillar1 collecting and storing my data according to the{" "}
                    <Link component={RouterLink} to="/privacy-policy" color="primary">
                      Privacy Policy
                    </Link>
                    . My information will be processed to respond to my inquiry.
                  </Typography>
                }
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                fullWidth
                size="large"
                disabled={isSubmitting || !formData.gdprConsent}
                sx={{
                  py: 1.5,
                  fontWeight: 600,
                  mt: 1,
                }}
              >
                {isSubmitting ? (
                  <CircularProgress
                    size={24}
                    color="inherit"
                  />
                ) : (
                  "Send Message"
                )}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </CardContent>

      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Message sent successfully! We'll be in touch soon.
        </Alert>
      </Snackbar>

      <Snackbar
        open={error}
        autoHideDuration={6000}
        onClose={handleCloseError}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Card>
  );
};

export default ContactForm;