import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Button, 
  Typography, 
  Snackbar, 
  Link,
  Stack,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  FormControlLabel,
  useTheme
} from '@mui/material';
import CookieIcon from '@mui/icons-material/Cookie';
import { Link as RouterLink } from 'react-router-dom';

const CookieConsent = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  
  // Cookie preferences state
  const [cookiePreferences, setCookiePreferences] = useState({
    necessary: true, // Always required
    analytics: false,
    preferences: false,
    marketing: false
  });

  useEffect(() => {
    // Check if user has already made cookie choices
    const consentGiven = localStorage.getItem('cookieConsentGiven');
    if (!consentGiven) {
      // Show banner after a short delay
      const timer = setTimeout(() => setOpen(true), 1000);
      return () => clearTimeout(timer);
    } else {
      // Load saved preferences
      try {
        const savedPreferences = JSON.parse(localStorage.getItem('cookiePreferences'));
        if (savedPreferences) {
          setCookiePreferences(savedPreferences);
        }
      } catch (e) {
        console.error("Error loading cookie preferences:", e);
      }
    }
  }, []);

  const handleAcceptAll = () => {
    const allAccepted = {
      necessary: true,
      analytics: true,
      preferences: true,
      marketing: true
    };
    
    setCookiePreferences(allAccepted);
    localStorage.setItem('cookieConsentGiven', 'true');
    localStorage.setItem('cookiePreferences', JSON.stringify(allAccepted));
    setOpen(false);
  };

  const handleAcceptNecessary = () => {
    const necessaryOnly = {
      necessary: true,
      analytics: false,
      preferences: false,
      marketing: false
    };
    
    setCookiePreferences(necessaryOnly);
    localStorage.setItem('cookieConsentGiven', 'true');
    localStorage.setItem('cookiePreferences', JSON.stringify(necessaryOnly));
    setOpen(false);
  };

  const handlePreferenceChange = (event) => {
    setCookiePreferences({
      ...cookiePreferences,
      [event.target.name]: event.target.checked
    });
  };

  const handleSavePreferences = () => {
    localStorage.setItem('cookieConsentGiven', 'true');
    localStorage.setItem('cookiePreferences', JSON.stringify(cookiePreferences));
    setShowSettings(false);
    setOpen(false);
  };

  const handleOpenSettings = () => {
    setShowSettings(true);
  };

  const handleCloseSettings = () => {
    setShowSettings(false);
  };

  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ 
          maxWidth: '100%',
          width: { xs: '95%', sm: '90%', md: '800px' },
          '& .MuiPaper-root': {
            backgroundColor: theme.palette.background.paper,
            boxShadow: theme.shadows[8],
            borderRadius: 2,
            p: 2
          }
        }}
      >
        <Box 
          sx={{ 
            p: 2, 
            bgcolor: 'background.paper', 
            borderRadius: 2,
            boxShadow: 3,
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5, mb: 1 }}>
            <CookieIcon color="primary" />
            <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
              Cookie Consent
            </Typography>
          </Box>
          
          <Typography variant="body2" color="text.secondary">
            We use cookies to improve your experience on our website. By continuing to browse, you consent to our use of cookies. 
            You can change your cookie settings at any time. For more information, please read our{' '}
            <Link component={RouterLink} to="/cookie-policy" color="primary">
              Cookie Policy
            </Link>{' '}
            and{' '}
            <Link component={RouterLink} to="/privacy-policy" color="primary">
              Privacy Policy
            </Link>.
          </Typography>
          
          <Stack 
            direction={{ xs: 'column', sm: 'row' }} 
            spacing={1.5} 
            sx={{ 
              mt: 1,
              display: 'flex',
              justifyContent: 'center',
              width: '100%'
            }}
          >
            <Button 
              variant="outlined" 
              size="small" 
              onClick={handleOpenSettings}
              sx={{ flexGrow: 1 }}
            >
              Cookie Settings
            </Button>
            <Button 
              variant="outlined" 
              size="small" 
              onClick={handleAcceptNecessary}
              sx={{ flexGrow: 1 }}
            >
              Necessary Only
            </Button>
            <Button 
              variant="contained" 
              size="small" 
              onClick={handleAcceptAll}
              sx={{ flexGrow: 1 }}
            >
              Accept All
            </Button>
          </Stack>
        </Box>
      </Snackbar>

      <Dialog 
        open={showSettings} 
        onClose={handleCloseSettings}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          Cookie Settings
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="body2" paragraph>
            You can choose which cookies you want to allow. We use cookies to enhance your experience on our site and to analyze traffic.
          </Typography>
          
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox 
                  checked={cookiePreferences.necessary} 
                  disabled 
                  name="necessary" 
                />
              }
              label={
                <Box>
                  <Typography variant="subtitle2">Necessary Cookies</Typography>
                  <Typography variant="caption" color="text.secondary">
                    These cookies are essential for the website to function properly and cannot be disabled.
                  </Typography>
                </Box>
              }
              sx={{ mb: 1, display: 'flex', alignItems: 'flex-start' }}
            />
            
            <Divider sx={{ my: 1.5 }} />
            
            <FormControlLabel
              control={
                <Checkbox 
                  checked={cookiePreferences.analytics} 
                  onChange={handlePreferenceChange} 
                  name="analytics" 
                />
              }
              label={
                <Box>
                  <Typography variant="subtitle2">Analytics Cookies</Typography>
                  <Typography variant="caption" color="text.secondary">
                    These cookies help us understand how visitors interact with our website by collecting anonymized information.
                  </Typography>
                </Box>
              }
              sx={{ mb: 1, display: 'flex', alignItems: 'flex-start' }}
            />
            
            <Divider sx={{ my: 1.5 }} />
            
            <FormControlLabel
              control={
                <Checkbox 
                  checked={cookiePreferences.preferences} 
                  onChange={handlePreferenceChange} 
                  name="preferences" 
                />
              }
              label={
                <Box>
                  <Typography variant="subtitle2">Preference Cookies</Typography>
                  <Typography variant="caption" color="text.secondary">
                    These cookies allow the website to remember choices you make and provide enhanced, personalized features.
                  </Typography>
                </Box>
              }
              sx={{ mb: 1, display: 'flex', alignItems: 'flex-start' }}
            />
            
            <Divider sx={{ my: 1.5 }} />
            
            <FormControlLabel
              control={
                <Checkbox 
                  checked={cookiePreferences.marketing} 
                  onChange={handlePreferenceChange} 
                  name="marketing" 
                />
              }
              label={
                <Box>
                  <Typography variant="subtitle2">Marketing Cookies</Typography>
                  <Typography variant="caption" color="text.secondary">
                    These cookies are used to track visitors across websites to enable display of relevant and engaging content.
                  </Typography>
                </Box>
              }
              sx={{ display: 'flex', alignItems: 'flex-start' }}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button onClick={handleCloseSettings} color="inherit">Cancel</Button>
          <Button onClick={handleSavePreferences} variant="contained">Save Preferences</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CookieConsent;